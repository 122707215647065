import { useAppDispatch, useAppSelector } from "../../app/hooks";
import PEPText from "./PEPText";
import { closeModal } from "../../reducers/modalReducer";
import { Modal } from "../../components/ui/Modal";
import { Button } from "../../components/ui/Button";

import styles from "./PEPModal.module.scss";

const PEPModal = () => {
  const {
    container,
    headerTitle,
    wrapper,
    header,
    modal,
    modalBaseMobile,
    modalBackDropMobile,
  } = styles;

  const dispatch = useAppDispatch();
  const { modalName } = useAppSelector((state) => state.modal);

  const isMobile = window.innerWidth < 768;

  const handleClose = () => dispatch(closeModal());

  return (
    <Modal
      isOpen={modalName === "modal-PEP"}
      onClose={handleClose}
      hideCloseButton={isMobile}
      header={<h1 className={headerTitle}>¿Qué es ser PEP?</h1>}
      {...(isMobile
        ? {
            classNames: {
              base: modalBaseMobile,
              backdrop: modalBackDropMobile,
            },
          }
        : undefined)}
      footer={
        isMobile && <Button text="Volver" onClick={handleClose} fullWidth />
      }
      centerFooter
      className={container}
    >
      <div className={wrapper}>
        <div className={modal}>
          <div className={header}>
            <p className="mb-1">
              Resolución 35/2023 de la UIF y modificatorias.
            </p>
            <div className="d-flex flex-row justify-content-between">
              <p className="mb-1 text-gray">RESOL-2023-35-APN-UIF#MEC</p>
              <p className="mb-1 text-gray">Fecha de publicación 02/03/2023</p>
            </div>
            <hr className="my-3" />
          </div>
          <PEPText />
        </div>
      </div>
    </Modal>
  );
};

export default PEPModal;

import { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";

// DEPENDENCIES
import * as Sentry from "@sentry/react";
import QRCode from "qrcode";
import { doc, getFirestore, onSnapshot, setDoc } from "@firebase/firestore";
import { useAppDispatch, useAppSelector } from "../../app/hooks";

//UTILS
import {
  onUpdatePrimaryButton,
  onUpdateSecondaryButton,
  onUpdateTertiaryButton,
} from "../../reducers/buttonsReducer";
import { updateToken } from "../../reducers/authReducer";
import { closeModal, openModal } from "../../reducers/modalReducer";
import {
  onUpdateOnClose,
  onUpdateQrImage,
  onUpdateScannedQr,
  onUpdateSuccessMessage,
} from "../../reducers/qrReducer";
import { onUpdateActiveTemplate } from "../../reducers/activeTemplateReducer";
import { sendIndividualDocument } from "../../connectors/connectors";

import useForm from "../../hooks/useForm";
import useWindowWidth from "../../hooks/useWindowWidth";
import {
  genericToastifyError,
  maxFileSize,
  qrStyle,
  validDniTypes,
} from "../../utils";

//TYPES
import { ComponentProps, DniImageType, FirebaseDniData } from "../../types";

// COMPONENTS
import {
  showToastMessage,
  ToastMessage,
} from "../../components/ui/ToastMessage";
import { Button } from "../../components/ui/Button";
import { InputFile } from "../../components/ui/InputFile";
import { LoadingCircle } from "../../components/ui/LoadingCircle";

// IMAGES & ICONS
import successIcon from "../../assets/images/close-window.svg";
import juridicalTemplate from "../../templates/juridical";
import physicalTemplate from "../../templates/physical";

import styles from "./UploadDniCard.module.scss";
import { heicToJpeg } from "../../app/utils";

const fields = [
  {
    name: "frontDNI",
    label: "Frente",
  },
  {
    name: "backDNI",
    label: "Dorso",
  },
];

const UploadDniPage = ({ formik }: ComponentProps) => {
  const {
    container,
    successContainer,
    mobile,
    withQr,
    mobileInput,
    button,
    loading,
  } = styles;

  const { handleBack, handleContinue } = useForm(formik);

  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const [dniData, setDniData] = useState<FirebaseDniData>({
    scannedQR: false,
    frontDNI: "",
    backDNI: "",
  });

  const [isLoadingData, setIsLoadingData] = useState<boolean>(true);
  const [isLoadingButton, setIsLoadingButton] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const { draftUUID } = useAppSelector((state) => state.onboardingData);
  const { onboardingType, favicon } = useAppSelector(
    (state) => state.activeTemplate
  );
  const { token: tokenStore } = useAppSelector((state) => state.authentication);

  const location = useLocation();
  const [searchParams] = useSearchParams();

  const dimensions = useWindowWidth();
  const isMobile = dimensions.width < 768;

  const continueWithQR = location.pathname.includes("dni");
  const urlDraftUUID = searchParams.get("uuid");
  const urlToken = searchParams.get("token");
  const urlTemplate = searchParams.get("template");

  const uuid = draftUUID || urlDraftUUID;

  const db = getFirestore();
  const dniRef = doc(db, "dniImages", uuid || " ");

  const saveFileToFirestore = (dniImage: DniImageType, fileName: string) =>
    setDoc(dniRef, { [dniImage]: fileName }, { merge: true });

  const disabledContinueWithQRButton =
    !dniData.frontDNI || !dniData.backDNI || error;

  const disablePrimaryButton =
    dniData.scannedQR || disabledContinueWithQRButton;

  const handleOnFileUpload = async (file: File, name: DniImageType) => {
    if (!uuid) return setError(true);

    setError(false);
    setIsLoadingButton(true);

    try {
      const convertedFile = await heicToJpeg(file);

      await sendIndividualDocument({
        draftUUID: uuid as string,
        documentType: name === "frontDNI" ? "DNI_FRONT" : "DNI_BACK",
        file: convertedFile,
      });
      saveFileToFirestore(name, convertedFile.name);
    } catch (error) {
      Sentry.captureException(error);
      setError(true);
      showToastMessage(genericToastifyError, {
        containerId: "formAlert",
        type: "error",
      });
    } finally {
      setIsLoadingButton(false);
    }
  };

  const loadTemplate = async () => {
    if (urlTemplate === "PHYSICAL") {
      const template = await physicalTemplate();
      dispatch(onUpdateActiveTemplate(template));
    }

    if (urlTemplate === "JURIDICAL") {
      dispatch(onUpdateActiveTemplate(juridicalTemplate));
    }
  };

  const handleSubmitWithQR = () => {
    setSuccess(true);
    setDoc(dniRef, { scannedQR: false }, { merge: true });
  };

  // Get template to load favicon in mobile flow
  useEffect(() => {
    if (!onboardingType) loadTemplate();
  }, []);

  useEffect(() => {
    if (favicon) {
      const newLink = document.createElement("link");
      newLink.rel = "icon";
      newLink.type = "image/png";
      newLink.href = favicon;
      document.head.appendChild(newLink);
    }
  }, [favicon]);

  // Get DNI images from Firestore or create a new document
  useEffect(() => {
    const unsub = onSnapshot(dniRef, (document) => {
      if (document.exists()) {
        const data = document.data() as FirebaseDniData;

        const dniImages: DniImageType[] = ["frontDNI", "backDNI"];

        dniImages.forEach((dniImage) => {
          const imageDNI = data[dniImage];

          setDniData((prevState) => ({
            ...prevState,
            [dniImage]: imageDNI,
            scannedQR: data.scannedQR,
          }));
          setIsLoadingData(false);
        });
      } else {
        setDoc(dniRef, dniData, { merge: true });
      }
    });

    return unsub;
  }, []);

  // Set QR information
  useEffect(() => {
    QRCode.toDataURL(
      `${window.location.origin}/dni?uuid=${draftUUID}&token=${tokenStore}&template=${onboardingType}`,
      qrStyle
    ).then((img) => dispatch(onUpdateQrImage(img)));

    dispatch(
      onUpdateSuccessMessage("Ya podés cargar las fotos desde tu teléfono.")
    );

    dispatch(
      onUpdateOnClose(() => {
        setDoc(dniRef, { scannedQR: false }, { merge: true });
      })
    );
  }, []);

  //Update QR modal
  useEffect(() => {
    if (continueWithQR && !isLoadingData) {
      setDoc(dniRef, { scannedQR: true }, { merge: true });
      localStorage.clear();
    }
  }, [continueWithQR, isLoadingData]);

  useEffect(() => {
    dniData.scannedQR && dispatch(onUpdateScannedQr(true));
  }, [dniData]);

  // Close modal when all files were uploaded
  useEffect(() => {
    if (!disablePrimaryButton) {
      dispatch(closeModal());
    }
  }, [dniData, error]);

  // Update token if it comes from URL
  useEffect(() => {
    if (urlToken) {
      dispatch(updateToken(urlToken));
    }
  }, [urlToken]);

  // Manage buttons
  useEffect(() => {
    if (!isMobile) {
      dispatch(
        onUpdateSecondaryButton({
          text: "Cargar con mi teléfono",
          disabled: false,
          isLoading: false,
          onClick: () => {
            dispatch(onUpdateScannedQr(false));
            dispatch(openModal({ name: "modal-qr" }));
          },
          show: true,
        })
      );

      dispatch(
        onUpdateTertiaryButton({
          text: "Volver",
          disabled: false,
          isLoading: false,
          onClick: handleBack,
          show: true,
        })
      );
    } else {
      dispatch(
        onUpdateSecondaryButton({
          text: "Volver",
          disabled: false,
          isLoading: false,
          onClick: handleBack,
          show: true,
        })
      );

      dispatch(
        onUpdateTertiaryButton({
          show: false,
        })
      );
    }

    dispatch(
      onUpdatePrimaryButton({
        text: "Continuar",
        disabled: disablePrimaryButton,
        isLoading: isLoadingButton,
        onClick: handleContinue,
        show: true,
      })
    );
  }, [dniData, isLoadingButton, isMobile]);

  return (
    <section>
      {success ? (
        <div className={successContainer}>
          <img src={successIcon} alt="successQRIcon" />
          <h2>¡Listo! Podés cerrar esta página y seguir en tu computadora.</h2>
        </div>
      ) : (
        <>
          {isLoadingData ? (
            <div className={loading}>
              <LoadingCircle width="50" />
            </div>
          ) : (
            <form
              className={`${container} ${isMobile ? mobile : ""} ${
                continueWithQR ? withQr : ""
              }`}
            >
              {continueWithQR && (
                <ToastMessage messageId="formAlert" position="top-center" />
              )}
              {continueWithQR && <h3>Cargá las fotos de tu DNI</h3>}
              {fields.map(({ name, label }, index) => (
                <InputFile
                  key={name}
                  name={name}
                  maxSize={maxFileSize}
                  validTypes={validDniTypes}
                  isMobile={isMobile}
                  text={
                    <p>
                      Arrastrá o <span>seleccioná un archivo</span> de tu
                      dispositivo
                    </p>
                  }
                  className={isMobile ? mobileInput : ""}
                  label={label}
                  infoText={`Cargá un archivo JPG, PNG, HEIC, HEIF o PDF de hasta ${maxFileSize}mb.`}
                  infoTextPosition="top"
                  fileData={
                    dniData[name as DniImageType] !== ""
                      ? { id: index, name: dniData[name as DniImageType] }
                      : undefined
                  }
                  hideDownloadIcon
                  onFileUpload={(file: File) =>
                    handleOnFileUpload(file, name as DniImageType)
                  }
                  onFileRemove={() => {
                    saveFileToFirestore(name as DniImageType, "");
                  }}
                />
              ))}
              {continueWithQR && (
                <Button
                  type="submit"
                  text="Confirmar"
                  className={button}
                  size="mobile"
                  disabled={disabledContinueWithQRButton}
                  isLoading={isLoadingButton}
                  onClick={handleSubmitWithQR}
                />
              )}
            </form>
          )}
        </>
      )}
    </section>
  );
};

export default UploadDniPage;
